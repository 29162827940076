
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// Safari 3.0+ "[object HTMLElementConstructor]"
// let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
let isIE = /*@cc_on!@*/false || !!document.documentMode;

if (isIE) {
    document.getElementById('IEwarning').style.display = "block";
}

// ChartJS
const Chart = require('chart.js');

// AlpineJS
// import 'alpinejs'

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
